<template>
    <y-modal
        ref="modal"
        :title="'تنظیمات ویجت'"
        @close="reset"
    >
        <template slot="body">
            <y-loading
                :active="$wait.is('loading-setting')"
                height="200px"
            >
                <!-- Show setting form -->
                <form
                    id="modal-form"
                    @submit.prevent="save"
                >
                    <y-form
                        v-if="form && form.length"
                        v-model="model"
                        :params="form"
                    />
                </form>
            </y-loading>
        </template>

        <template
            v-if="!$wait.is('loading-setting')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="green"
                    loading-on="saving-setting"
                    form="modal-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>
    import { YModal, YForm } from '@deps/index';

    export default {
        name: 'WidgetSettingsModal',

        components: {
            YForm,
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form : null,
                model: null,

                id       : null,
                baseSize : null,
                baseTitle: null,
                baseColor: null,
            };
        },

        methods: {
            /**
             * Open setting modal
             *
             * @param id
             */
            open(id) {
                this.$refs.modal.open();
                this.id = id;
                this.fetch();
            },

            /**
             * Close setting modal
             */
            close() {
                this.$refs.modal.close();
            },

            /**
             * Reset forms
             */
            reset() {
                this.id = null;
                this.model = null;
                this.form = null;
            },

            /**
             * Fetch Form Modal
             */
            fetch() {
                this.$wait.start('loading-setting');
                this.$services.Panel.widgetSettingsFetch({ id: this.id }).then((response) => {
                    this.form = response.data.metadata.form;
                    this.model = response.data.results;
                    this.$set(this, 'baseSize', this.model.size);
                    this.$set(this, 'baseTitle', this.model.title);
                    this.$set(this, 'baseColor', this.model.color);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-setting'));
            },

            /**
             * Save setting
             */
            save() {
                this.$wait.start('saving-setting');
                this.$services.Panel.widgetSettingsSave({ id: this.id, ...this.model }).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('dashboard.widget.title') }));
                    if (this.baseSize !== this.model.size || this.baseTitle !== this.model.title || this.baseColor !== this.model.color) {
                        this.$emit('done');
                    } else {
                        this.$emit('done', this.id);
                    }
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('saving-setting');
                });
            },
        },
    };

</script>
