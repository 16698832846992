<template>
    <div>
        <main>
            <y-widgets-list />

            <!-- DEPS: useless -->
            <!-- <y-form :params="form" /> -->
        </main>
    </div>
</template>

<script>
    import PageMixin from '@/mixins/Page';
    import YWidgetsList from '@blocks/dashboard/WidgetsList';
    // import { YForm } from '@deps';

    export default {
        name: 'Dashboard',

        components: {
            YWidgetsList,
            // YForm,
        },

        mixins: [
            PageMixin,
        ],

        /**
         * @inheritDoc
         */
        data() {
            return {
                form: [],
            };
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('general.dashboard'),
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
            ];
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$wait.end('loading-page');
        },
    };
</script>
