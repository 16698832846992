<template>
    <div>
        <!-- PageHead -->
        <y-page-head
            :title="$t('settings.title')"
            icon="md-settings"
        >
            <template slot="actions">
                <input
                    v-model="search"
                    type="text"
                    :placeholder="$t('settings.search_placeholder')"
                    class="sm"
                >
            </template>
        </y-page-head>

        <!-- Main Content -->
        <main>
            <section
                v-for="(category, index) in filteredItems"
                :key="index"
                class="setting-category panel"
            >
                <div class="right">
                    <img
                        :src="require(`@/modules/settings/assets/images/categories/${icon(index)}.svg`)"
                        alt="category-image"
                    >
                    <div class="title">
                        {{ $t('settings.categories.'+index) }}
                    </div>
                </div>

                <div class="left">
                    <y-button
                        v-for="item in category"
                        :key="`setting-${item.slug}`"
                        size="sm"
                        :with-icon="item.upstream ? 'md-atom' : ''"
                        :color="item.upstream ? 'gray' : ''"
                        :data-keywords="`${item.slug},${item.title}`"
                        @click.prevent="openModal(item.slug, item.title)"
                    >
                        {{ item.title }}
                    </y-button>
                </div>
            </section>

            <setting-modal ref="settingModal" />
        </main>
    </div>
</template>

<script>
    import PageMixin from '@/mixins/Page';

    // Helper
    import { groupByKey } from '@nodes/helpers/array';

    // Components
    import SettingModal from '@/modules/settings/components/SettingModal';

    export default {
        name: 'List',

        components: {
            SettingModal,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.setting.title')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('settings.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                settings: [],
                groupBy : groupByKey('group'),
                search  : null,
            };
        },

        computed: {
            /**
             * Set filtered items based on search
             */
            filteredItems() {
                if (!this.search) {
                    return this.groupBy(this.settings, 'group');
                }
                const search = this.search.toLowerCase();
                const results = this.settings.filter((item) => item.title.toLowerCase().includes(search) || item.slug.toLowerCase().includes(search));
                return this.groupBy(results, 'group');
            },

        },

        methods: {

            /**
             * Fetch settings
             */
            fetch() {
                return this.$services.Setting.list().then((response) => {
                    this.settings = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open set setting modal
             *
             * @param slug
             * @param title
             */
            openModal(slug, title) {
                this.$refs.settingModal.open(slug, title);
            },

            /**
             * Group icon
             *
             * @param index
             */
            icon(index) {
                const icons = Object.keys(this.$t('settings.categories'));
                return icons.includes(index) ? index : 'general';
            },

        },
    };
</script>
