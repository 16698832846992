<template>
    <y-loading
        :active="$wait.is('loading-widgets')"
        height="calc( 100vh - 239px )"
    >
        <div
            v-if="layout && layout.length"
            class="dir-ltr"
        >
            <grid-layout
                ref="gridLayout"
                :layout.sync="layout"
                :col-num="4"
                :row-height="5"
                :is-draggable="isManage"
                :is-resizable="false"
                :is-mirrored="false"
                :vertical-compact="true"
                :margin="margin"
                :responsive="true"
                :breakpoints="{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }"
                :cols="{ lg: 4, md: 4, sm: 4, xs: 2, xxs: 1 }"
                :use-css-transforms="true"
                @layout-ready="resizeWidgets"
            >
                <grid-item
                    v-for="(item) in layout"
                    :key="ref(item.i)"
                    :ref="ref(item.i)"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    @moved="saveSort"
                >
                    <y-widget
                        :key="`widget-${item.i}`"
                        :element="item"
                        :refresh-id="refreshWidgetId"
                        @settings="$refs.WidgetSettingsModal.open($event)"
                        @updated="resizeWidgets"
                        @delete="$refs.DeleteModal.open($event)"
                        @done-refresh="refreshWidgetId = null"
                    />
                </grid-item>
            </grid-layout>
        </div>

        <!-- Settings modal -->
        <y-widget-settings-modal
            ref="WidgetSettingsModal"
            @done="widgetSetting"
        />

        <!-- Delete modal -->
        <y-delete-modal
            ref="DeleteModal"
            confirm-only
            :type="$t('dashboard.widget.title')"
            @confirm="deleteWidget"
        />
    </y-loading>
</template>

<script>

    import EventBus from '@/mixins/EventBus';
    import { GridLayout, GridItem } from 'vue-grid-layout';
    import YWidget from '@blocks/dashboard/Widget';
    import YWidgetSettingsModal from '@blocks/dashboard/WidgetSettingsModal';
    import YDeleteModal from '@deps/DeleteModal';

    export default {
        name: 'DashboardWidgets',

        components: {
            GridLayout,
            GridItem,
            YWidget,
            YWidgetSettingsModal,
            YDeleteModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                margin      : [10, 10],
                layout      : [],
                componentKey: 0,

                colors: {
                    '#F13A30': 'red',
                    '#F18F1C': 'orange',
                    '#F1C40F': 'yellow',
                    '#1EC847': 'green',
                    '#009C74': 'teal',
                    '#1875F0': 'blue',
                    '#5553CE': 'purple',
                    '#607D8B': 'gray',
                    '#FFFFFF': 'white',
                },

                addMessage : this.$t('dashboard.widget.added'),
                sortMessage: this.$t('dashboard.widget.sorted'),

                refreshWidgetId: null,
            };
        },

        computed: {
            /**
             * Check if dashboard is in editing mode
             */
            isManage() {
                return this.$store.getters['dashboard/manage'];
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.fetch();
            EventBus.$on('addWidgetToList', (payload) => this.addNewWidget(payload));
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            EventBus.$off('addWidgetToList');
        },

        methods: {
            /**
             * Fetch list of users widgets
             */
            fetch() {
                const storage = JSON.parse( localStorage.getItem( this.$configs.store.key ) );
                if (storage?.auth?.isLoggedIn) {
                    const layout = [];
                    this.$wait.start('loading-widgets');
                    this.$services.Panel.widgetsList().then((response) => {
                        response.data.results.forEach((widget) => {
                            layout.push({
                                ...widget,
                                x    : widget.x || 0,
                                y    : widget.y || 0,
                                w    : widget.size,
                                h    : 1,
                                i    : widget.id,
                                color: this.convertColor(widget.color) || 'white',
                            });
                        });
                        this.$set(this, 'layout', layout);
                    }).catch((error) => {
                        this.handleError(error);
                    }).finally(() => this.$wait.end('loading-widgets'));
                }
            },

            /**
             * Widget Setting Done
             *
             * @param {string} id - ID of the widget
             */
            widgetSetting(id) {
                if (id) {
                    this.$set(this, 'refreshWidgetId', id);
                } else {
                    this.fetch();
                }
            },

            /**
             * Add new widget
             *
             * @param payload
             */
            addNewWidget(payload) {
                this.$wait.start('loading-widgets');
                const last = this.layout[this.layout.length - 1];
                const i = payload.id;
                const lastY = last ? last.y + 1 : 0;
                const y = (last && (4 - last.x - last.w) >= payload.size) ? last.y : lastY;
                const x = (last && (4 - last.x - last.w) >= payload.size) ? last.x + last.w : 0;
                const widget = {
                    ...payload,
                    x,
                    y,
                    i,
                    w    : payload.size,
                    h    : 1,
                    color: this.convertColor(payload.color) || 'white',
                };
                this.$toast.success(this.$t('dashboard.widget.added'));
                this.layout.push(widget);
                this.$wait.end('loading-widgets');
                this.saveSort();
            },

            /**
             * Resize widgets
             */
            resizeWidgets() {
                this.layout.forEach((item, index) => {
                    this.calculateSize(this.$refs[this.ref(item.i)][0], index);
                });
            },

            /**
             * Calculate widget height
             *
             * @param widget
             * @param index
             */
            calculateSize(widget, index) {
                const newSize = widget.$slots.default[0].elm.getBoundingClientRect();
                const pos = widget.calcWH(newSize.height, newSize.width);
                const item = this.layout[index];
                item.h = pos.h;
                this.$refs.gridLayout.resizeEvent('resizeend', String(index), item.x, item.y, item.h, item.w);
            },

            /**
             * Save sort of widgets
             */
            saveSort() {
                const ids = {};
                this.layout.forEach((item) => {
                    ids[item.i] = {
                        x: item.x,
                        y: item.y,
                    };
                });
                this.$services.Panel.widgetsSort({ ids }).then(() => {
                    this.$toast.success(this.sortMessage);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Delete widget
             *
             * @param i
             */
            deleteWidget(i) {
                const index = this.layout.findIndex((el) => el.i === i);
                this.layout.splice(index, 1);
                this.saveSort();
                this.$toast.success(this.$t('messages.destroy.success', { type: this.$t('dashboard.widget.title') }));
            },

            /**
             * Generate ref key
             *
             * @param id
             */
            ref(id) {
                return `grid-item-${id}`;
            },

            /**
             * Color
             *
             * @param color
             */
            convertColor(color) {
                return this.get(this.colors, color.toUpperCase(), color);
            },
        },
    };
</script>
