<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-user-welcome col-md-9 col-sm-12 col-center"
    >
        <y-row
            wrap
            justify="center"
            align-items="center"
        >
            <y-col
                xs="12"
                md="9"
                lg="4"
            >
                <img
                    class="greeting-thumb"
                    src="@/assets/images/welcome.svg"
                    alt="Illustration of User Welcome"
                    draggable="false"
                >
            </y-col>
            <y-col
                xs="12"
                md="12"
                lg="8"
            >
                <h4 class="greeting-title">
                    {{ $t('dashboard.widgets.welcome.greeting') }}
                </h4>
                <p class="about-groot">
                    {{ $t('dashboard.widgets.welcome.about_quark') }}
                </p>
                <p class="about-quark">
                    {{ $t('dashboard.widgets.welcome.about_quark_desc') }}
                </p>
            </y-col>
        </y-row>
        <y-row
            justify="end"
            size="xs"
        >
            <y-button
                with-icon="md-link"
                tag="a"
                size="sm"
                class="blue ml10 mb5"
                href="https://yasna.team/"
                rel="noreferrer noopener"
                target="_blank"
            >
                {{ $t('dashboard.widgets.welcome.homepage_link_info') }}
            </y-button>
            <y-button
                with-icon="md-link"
                tag="a"
                size="sm"
                class="mb5"
                href="https://my.yasna.team/"
                rel="noreferrer noopener"
                target="_blank"
            >
                {{ $t('dashboard.widgets.welcome.homepage_link_support') }}
            </y-button>
        </y-row>
    </y-panel>
</template>

<script>
    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'WelcomeWidget',

        mixins: [WidgetMixin],

        props: {
            /**
             * Widget element
             */
            element: Object,
        },

    };
</script>
