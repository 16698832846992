<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget widget-content-last-posts"
    >
        <template
            v-if="model"
            slot="functions"
        >
            <y-button
                v-if="model.can_create"
                size="xs"
                tag="router-link"
                :to="makeEditLink()"
            >
                {{ $t('button.create') }}
            </y-button>
            <y-button
                v-if="model.can_list"
                size="xs"
                tag="router-link"
                :to="makeListLink()"
            >
                {{ $t('button.more') }}
            </y-button>
            <y-button
                v-if="!isManage"
                :key="5"
                size="sm"
                icon="md-reload"
                class="color-green"
                @click="fetch"
            />
        </template>

        <y-loading
            :active="isLoading && !model"
            height="100px"
        >
            <template v-if="Array.isArray(model)">
                <div
                    class="panel empty-state"
                    :style="heightStyle"
                >
                    <div class="desc">
                        <div class="title">
                            {{ $t('dashboard.widget.need_setting') }}
                        </div>
                        <div class="action ta-c">
                            <y-button @click="$emit('open-setting')">
                                {{ $t('button.setting') }}
                            </y-button>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else-if="get(model, 'posts', []).length > 0">
                <!-- Search in contents -->
                <form
                    v-if="model.can_list"
                    @submit.prevent="doSearch"
                >
                    <div class="field icon">
                        <input
                            v-model="search"
                            type="text"
                            class="sm"
                            :placeholder="$t('fields.search')"
                        >
                        <i class="icon md-magnify" />
                    </div>
                </form>

                <table class="table mb0">
                    <tbody>
                        <tr
                            v-for="row in model.posts"
                            :key="row.id"
                        >
                            <td>
                                <div class="form-title mb15">
                                    {{ row.title }}
                                </div>
                                <y-form-date-label
                                    class="mb0 color-gray-light"
                                    :element="makeDateELement(row)"
                                />
                            </td>
                            <td
                                v-if="model.can_edit"
                                class="col-min"
                            >
                                <y-button
                                    size="xs"
                                    tag="router-link"
                                    :to="makeEditLink(row.id)"
                                >
                                    {{ $t('button.edit') }}
                                </y-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>

            <y-empty-state
                v-else
                height="250"
                class="mb0"
                :title="$t('dashboard.widgets.content.empty')"
            />
        </y-loading>
    </y-panel>
</template>

<script>

    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'ContentLastPostsWidget',

        components: {
            YFormDateLabel: () => import('@deps/form/elements/DateLabel'),
        },

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                search: null,
            };
        },

        computed: {
            /**
             * Height of the Widget
             */
            heightStyle() {
                return {
                    height         : '150px',
                    slug           : null,
                    routeNameEdit  : null,
                    routeNameCreate: null,
                    routeNameList  : null,
                };
            },
        },


        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {

            /**
             * Fetch user login history
             */
            fetch() {
                this.startLoading();
                return this.$services.Panel.widgetContent({ id: this.element.id }).then((response) => {
                    this.model = response.data.results;
                    this.slug = response.data.results.template;
                    if (this.slug === 'market') {
                        this.routeNameEdit = 'market-product-edit';
                        this.routeNameCreate = 'market-product-create';
                        this.routeNameList = 'market-product-list';
                    } else {
                        this.routeNameEdit = 'content-post-edit';
                        this.routeNameCreate = 'content-post-create';
                        this.routeNameList = 'content-post-list';
                    }
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.stopLoading();
                    this.updated();
                });
            },

            /**
             * Make date element
             *
             * @param row
             */
            makeDateELement(row) {
                return {
                    prefix : '',
                    postfix: '',
                    value  : row.created_at,
                };
            },

            /**
             * Make create link
             */
            makeListLink() {
                return {
                    name  : this.routeNameList,
                    params: {
                        type: this.model.type,
                    },
                };
            },

            /**
             * Make edit link
             *
             * @param id
             */
            makeEditLink(id = null) {
                return {
                    name  : id ? this.routeNameEdit : this.routeNameCreate,
                    params: {
                        type: this.model.type,
                        id,
                    },
                };
            },

            /**
             * Search in content
             */
            doSearch() {
                if (!this.search) {
                    return;
                }

                const search = this.search.trim();
                if ( search && search !== '' ) {
                    const to = this.makeListLink();
                    to.query = { search };
                    this.$router.push(to);
                }
            },

        },

    };
</script>
