<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-order-finder"
    >
        <y-loading
            :active="isLoading"
            height="100px"
        >
            <div class="container">
                <!-- Form -->
                <form
                    id="order-finder-form"
                    class="finder-form"
                    @submit.prevent="searchOrder"
                >
                    <y-form-field
                        v-model="query"
                        type="number"
                        :placeholder="$t('dashboard.widgets.order_finder.form.placeholder')"
                        no-label
                        name="search"
                        class="mb0"
                    />

                    <y-button
                        color="blue"
                        loading-on="loading-order-result"
                        form="order-finder-form"
                    >
                        {{ $t('dashboard.widgets.order_finder.form.button') }}
                    </y-button>
                </form>

                <!-- Results -->
                <y-loading
                    v-if="searched"
                    :active="$wait.is('loading-result')"
                    height="250px"
                >
                    <div
                        v-if="results && endFetch"
                        class="finder-result"
                    >
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="detail-item">
                                    <div class="subtitle">
                                        {{ $t('dashboard.widgets.order_finder.result.info.user_name') }}
                                    </div>
                                    <div class="title">
                                        {{ results.user.name_full }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="detail-item">
                                    <div class="subtitle">
                                        {{ $t('dashboard.widgets.order_finder.result.info.date') }}
                                    </div>
                                    <div class="title">
                                        {{ results.effected_at | date('D MMMM YYYY') }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="detail-item">
                                    <div class="subtitle">
                                        {{ $t('dashboard.widgets.order_finder.result.info.order_status') }}
                                    </div>
                                    <div class="label rounded blue">
                                        {{ results.status_title }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="detail-item">
                                    <div class="subtitle">
                                        {{ $t('dashboard.widgets.order_finder.result.info.payable_amount') }}
                                    </div>
                                    <div class="title">
                                        <y-form-amount
                                            class="mb0 d-ib"
                                            :element="{ value: results.payable_amount }"
                                        />
                                        <span>{{ ` ${results.currency_title}` }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="detail-item">
                                    <div class="subtitle">
                                        {{ $t('dashboard.widgets.order_finder.result.info.paid_amount') }}
                                    </div>
                                    <div class="title">
                                        <y-form-amount
                                            class="mb0 d-ib"
                                            :element="{ value: results.paid_amount }"
                                        />
                                        <span>{{ ` ${results.currency_title}` }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="detail-item">
                                    <div class="subtitle">
                                        {{ $t('dashboard.widgets.order_finder.result.info.pay_status') }}
                                    </div>
                                    <div
                                        class="label rounded"
                                        :class="payStatusClass"
                                    >
                                        {{ results.payment_status_title }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="gray-lightest">

                        <div class="d-f justify-content-between results-actions">
                            <y-button
                                class="color-red"
                                @click.prevent.native="reset"
                            >
                                {{ $t('dashboard.widgets.order_finder.form.reset') }}
                            </y-button>

                            <y-button
                                tag="router-link"
                                :to="cartDetailsRoute"
                            >
                                {{ $t('dashboard.widgets.order_finder.result.view_order') }}
                            </y-button>
                        </div>
                    </div>

                    <!-- Results - No Result -->
                    <y-empty-state
                        v-else-if="!results && endFetch"
                        height="250"
                        class="mb0"
                        :title="$t('dashboard.widgets.order_finder.result.no_result')"
                    >
                        <y-button
                            class="color-red reset-empty"
                            @click.prevent.native="reset"
                        >
                            {{ $t('dashboard.widgets.order_finder.form.reset') }}
                        </y-button>
                    </y-empty-state>
                </y-loading>
            </div>
        </y-loading>
    </y-panel>
</template>

<script>
    import YFormField from '@deps/form/FormField';
    import YFormAmount from '@deps/form/elements/Amount';
    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'OrganizationFinderWidget',

        components: {
            YFormField,
            YFormAmount,
        },

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                query   : '',
                metadata: null,
                results : null,
                loading : false,
                searched: false,
                endFetch: false,
            };
        },

        computed: {
            /**
             * Generate cart details route
             */
            cartDetailsRoute() {
                return {
                    name  : 'cart-details',
                    params: {
                        id  : this.results.id,
                        slug: this.results.type.slug,
                    },
                };
            },

            /**
             * Return pay status class
             */
            payStatusClass() {
                let className;
                if ((this.results.payable_amount - this.results.paid_amount) > 0) {
                    className = 'orange';
                }
                if (this.results.payable_amount === this.results.paid_amount) {
                    className = 'green';
                }
                if ((this.results.payable_amount - this.results.paid_amount) < 0) {
                    className = 'red';
                }
                return className;
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.stopLoading();
        },

        methods: {

            /**
             * Search
             */
            searchOrder() { // eslint-disable-line consistent-return
                if (!this.query) {
                    return false;
                }
                this.endFetch = false;
                this.searched = true;
                this.$wait.start('loading-order-result');
                this.updated();
                this.results = null;
                const params = {
                    id    : this.element.id,
                    number: this.query,
                };
                this.$services.Cart.orderTrack(params).then((response) => {
                    if (response.data.results.length !== 0) {
                        this.results = response.data.results;
                    }
                    this.metadata = response.data.metadata;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-order-result');
                    this.updated();
                    this.endFetch = true;
                });
            },

            /**
             * More Result Link
             */
            moreResultLink() {
                this.$router.push({
                    name : 'cart-details',
                    query: {
                        search: this.query,
                    },
                });
            },

            /**
             * Reset Search
             */
            reset() {
                this.searched = false;
                this.endFetch = false;
                this.results = null;
                this.query = null;
                this.updated();
            },

        },

    };
</script>
