<template>
    <y-panel-grid :service="$services.Division.divisionsGrid" />
</template>

<script>

    import GridPageMixin from '@/mixins/GridPage';

    export default {

        name: 'DivisionsList',

        mixins: [GridPageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.divisions.list')),
            ];
        },

    };

</script>
