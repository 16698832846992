<template>
    <y-panel id="page-head">
        <div class="top">
            <div class="right">
                <div class="d-f align-items-center justify-content-start">
                    <div
                        v-if="user.avatar && user.avatar[0]"
                        :style="{backgroundImage:`url(${user.avatar[0].link})`}"
                        class="persons-view-profile__avatar"
                    />
                    <div>
                        <div>
                            <span class="persons-view-profile__name">{{ user.name_full }}</span>
                            <template v-if="user.simple_roles && user.simple_roles.length">
                                <span
                                    v-for="role in user.simple_roles"
                                    :key="role.id"
                                    class="persons-view-profile__role badge label"
                                >
                                    {{ role.role.title }}
                                </span>
                            </template>
                        </div>
                        <y-form-email-label
                            class="mb5 mt5"
                            :content="user.email"
                            icon="md-email"
                        />
                        <y-form-phone-label
                            class="mb0"
                            :element="{ value: user.mobile }"
                            icon="md-phone"
                        />
                        <div class="d-f align-items-center justify-content-start">
                            <i class="icon md-two-factor-authentication ml5 d-ib" />
                            <span class="badge label">{{ metadata && metadata.is_enabled_2fa ? $t('general.status.active') : $t('general.status.inactive') }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="left">
                <slot name="actions" />
            </div>
        </div>

        <div
            v-if="$slots.tabs"
            class="tabs"
        >
            <slot name="tabs" />
        </div>
    </y-panel>
</template>

<script>
    import YFormEmailLabel from '@deps/form/elements/EmailLabel';
    import YFormPhoneLabel from '@deps/form/elements/PhoneLabel';

    export default {

        name: 'ProfilePageHead',

        components: {
            YFormEmailLabel,
            YFormPhoneLabel,
        },

        props: {
            user    : Object,
            metadata: Object,
        },

    };
</script>
