/*-----------------------------------------------------------------
- Panel widgets
-----------------------------------------------------------------*/
import YWidgetContentLastPostsWidget from './ContentLastPostsWidget';
import YWidgetCounter from './Counter';
import YWidgetPersonsLoginHistoryWidget from './PersonsLoginHistoryWidget';
import YWidgetPersonsUserInfoWidget from './UserInfoWidget';
import YWidgetPersonsUserFinderWidget from './UserFinderWidget';
import YWidgetPersonsUserCountsWidget from './UserCountsWidget';
import YWidgetPersonsOrganizationFinderWidget from './OrganizationFinderWidget';
import YWidgetPersonsUserRegistrationChartWidget from './UserRegistraionChartWidget';
import YWidgetPanelWelcomeWidget from './WelcomeWidget';
import YWidgetCartOrderFinderWidget from './CartOrderFinderWidget';

export default {
    YWidgetContentLastPostsWidget,
    YWidgetCounter,
    YWidgetPersonsLoginHistoryWidget,
    YWidgetPersonsUserInfoWidget,
    YWidgetPersonsUserFinderWidget,
    YWidgetPersonsUserCountsWidget,
    YWidgetPersonsOrganizationFinderWidget,
    YWidgetPersonsUserRegistrationChartWidget,
    YWidgetPanelWelcomeWidget,
    YWidgetCartOrderFinderWidget,
};
