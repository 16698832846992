<template>
    <div class="widget-wrapper">
        <!-- Widget -->
        <!-- eslint-disable-next-line vue/require-component-is -->
        <component
            :is="name"
            v-if="isRegisteredWidget"
            :key="`widget-${element.id}`"
            ref="Widget"
            :element="element"
            :refresh-id="refreshId"
            @updated="$emit('updated')"
            @open-setting="openSettings"
            @done-refresh="$emit('done-refresh')"
        />
        <!-- !Widget -->

        <div
            v-if="isManage"
            class="widget-settings button-group"
        >
            <y-button
                :key="3"
                size="sm"
                icon="md-cogs"
                class="color-blue"
                @click="openSettings"
            />
            <y-button
                size="sm"
                icon="md-close"
                class="color-red"
                @click.native="deleteWidget"
            />
        </div>
    </div>
</template>

<script>
    import Widgets from '@blocks/dashboard/widgets/index';

    export default {
        name: 'PanelWidget',

        components: {
            ...Widgets,
        },

        props: {
            /**
             * Widget element
             */
            element: Object,

            /**
             * Id of the widget to refresh
             */
            refreshId: String,
        },

        computed: {
            /**
             * Return title of element
             */
            title() {
                return this.get(this.element, 'title');
            },

            /**
             * Get widget name
             */
            name() {
                return `YWidget${this.element.slug.replace('-', '')}`;
            },

            /**
             * Check if dashboard is in editing mode
             */
            isManage() {
                return this.$store.getters['dashboard/manage'];
            },
        },

        methods: {
            /**
             * Remove a widget
             */
            deleteWidget() {
                this.$emit('delete', this.element.i);
            },

            /**
             * Open settings modal
             */
            openSettings() {
                this.$emit('settings', this.element.i);
            },

            /**
             * Check if widget is registered
             */
            isRegisteredWidget() {
                return Widgets.hasOwnProperty(this.name);
            },
        },
    };
</script>
