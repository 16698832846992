<template>
    <y-modal ref="Modal">
        <div slot="body">
            <y-alert
                v-if="isRequired"
                color="red"
                :message="$t('profile.two_fa.disable_required_2fa')"
            />

            <p class="mb0">
                {{ $t('profile.two_fa.disable_2fa') }}
            </p>
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    loading-on="disabling"
                    @click.prevent.native="disable2fa"
                >
                    {{ $t('button.sure') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal, YAlert } from '@deps';

    export default {
        name: 'DeleteModal',

        components: {
            YModal,
            YAlert,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id        : null,
                isRequired: false,
            };
        },

        methods: {
            /**
             * Remove item
             */
            disable2fa() {
                this.$wait.start('disabling');
                const params = {
                    id: this.id,
                };
                this.$services.Person.disableUserTwoFactorAuthentication(params).then(() => {
                    this.$toast.success(this.$t('messages.operation.success'));
                    this.$emit('done');
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.start('disabling'));
            },

            /**
             * Open modal
             *
             * @param {string} id - id of the user
             * @param {boolean} isRequired
             */
            open(id, isRequired) {
                this.id = id;
                this.isRequired = isRequired;
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.id = null;
                this.isRequired = null;
            },

        },
    };
</script>
