<template>
    <div>
        <y-profile-page-head
            v-if="user"
            :user="user"
            icon="md-face-profile"
        >
            <!-- Tabs -->
            <template slot="tabs">
                <a
                    v-for="tab in tabs"
                    :key="`tab-title-${tab.slug}`"
                    href="#"
                    :class="{active: activeTab === tab.slug}"
                    @click.prevent="changeTab(tab.slug)"
                >
                    {{ tab.title }}
                </a>
            </template>

            <template slot="actions">
                <y-button
                    tag="router-link"
                    :to="userEditLink()"
                >
                    {{ $t('person.user.edit_user') }}
                </y-button>
                <y-button @click.prevent="openGlobalModal('Persons-ChangePasswordModal', { id: id })">
                    {{ $t('profile.password.title') }}
                </y-button>
                <y-button
                    v-if="metadata && metadata.is_enabled_2fa"
                    color="red"
                    @click.prevent="$refs.ProfileDisableTwoFA.open(id, metadata.is_required_2fa)"
                >
                    {{ $t('profile.two_fa.disable_2fa_button') }}
                </y-button>
                <y-button
                    v-if="!hideImpersonate || isDeveloper"
                    class="color-red"
                    :disabled="isImpersonateMode"
                    @click="doImpersonate"
                >
                    {{ $t('permissions.impersonate') }}
                </y-button>
            </template>
        </y-profile-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main v-if="activeTab">
                <component
                    :is="tabComponent"
                    :active-tab="activeTab"
                    :data="tabData(activeTab)"
                />
            </main>
        </y-loading>

        <y-profile-disable-two-f-a
            ref="ProfileDisableTwoFA"
            @done="fetch"
        />
    </div>
</template>

<script>
    import { toCamelCase, capitalize } from '@nodes/helpers/string';
    import GlobalMixin from '@/mixins/Global';

    import YProfilePageHead from '@/modules/persons/components/profile/Profile/PageHead';

    // Tabs
    import YProfileBasicInfo from '@/modules/persons/components/profile/Profile/Tabs/BasicInfo';
    import YProfileAddresses from '@/modules/persons/components/profile/Profile/Tabs/Addresses';
    import YProfileTransactions from '@/modules/persons/components/profile/Profile/Tabs/Transactions';
    import YProfileLoginHistory from '@/modules/persons/components/profile/Profile/Tabs/LoginHistory';

    // Modals
    import YProfileDisableTwoFA from '@/modules/persons/components/profile/Profile/DisableModal';

    export default {
        name: 'UserProfile',

        components: {
            YProfilePageHead,
            YProfileBasicInfo,
            YProfileAddresses,
            YProfileTransactions,
            YProfileLoginHistory,
            YProfileDisableTwoFA,
        },

        mixins: [GlobalMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.persons.users.list')),
                this.$bc(this.$t('breadcrumbs.persons.users.profile')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('person.profile.title', [this.name]),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                activeTab      : null,
                user           : null,
                metadata       : null,
                hideImpersonate: true,
            };
        },

        computed: {
            /**
             * Return user id from router params
             */
            id() {
                return this.$route.params.id;
            },

            /**
             * Return user name
             */
            name() {
                return this.user ? this.user.name_full : '';
            },

            /**
             * Return the Tabs
             */
            tabs() {
                return this.user && this.user.tabs && this.user.tabs.length ? this.user.tabs : [];
            },

            /**
             * Tab Component
             */
            tabComponent() {
                return `YProfile${toCamelCase(capitalize(this.activeTab))}`;
            },

            /**
             * Check if this is in impersonate mode
             */
            isImpersonateMode() {
                return !!localStorage.getItem('admin_token');
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.fetch();
        },

        methods: {
            /**
             * Fetch User Profile data
             */
            fetch() { // eslint-disable-line consistent-return
                const params = {
                    id: this.id,
                };

                return this.$services.Person.viewProfile(params).then((response) => {
                    this.$set(this, 'user', response.data.results);
                    this.$set(this, 'metadata', response.data.metadata);
                    this.registerGlobalModals(response.data.metadata.modals);
                    if (!this.activeTab) {
                        this.activeTab = 'basic_info';
                    }
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },

            /**
             * Return Tab Data
             * 
             * @param {string} tab - The slug of the active tab
             */
            tabData(tab) {
                let tabData;
                Object.keys(this.tabs).forEach((key) => {
                    if (this.tabs[key].slug === tab) {
                        tabData = this.tabs[key];
                    }
                });
                return tabData;
            },

            /**
             * Change the tab
             * 
             * @param {string} tabSlug - Slug of the Tab
             */
            changeTab(tabSlug) {
                this.activeTab = tabSlug;
                if (this.$route.query && this.$route.query.page) {
                    const query = { ...this.$route.query };
                    delete query.page;
                    this.$router.replace({ query });
                }
            },

            /**
             * Impersonate User
             */
            doImpersonate() {
                localStorage.setItem('admin_token', localStorage.getItem('token'));

                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Person.impersonate(params).then((response) => {
                    localStorage.setItem('token', response.data.results.access_token);
                    window.location.reload();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Check to show impersonate
             */
            impersonateButton() {
                let stop = false;
                if (this.user && !stop) {
                    this.user.simple_roles.forEach((i) => {
                        if (i.role.slug === 'manager') {
                            this.hideImpersonate = true;
                            stop = true;
                        } else if (i.role.slug === 'super') {
                            this.hideImpersonate = true;
                            stop = true;
                        } else {
                            this.hideImpersonate = false;
                        }
                    });
                }
            },

            /**
             * User edit link
             */
            userEditLink() {
                return {
                    name  : 'persons-users-edit',
                    params: {
                        id: this.$route.params.id,
                    },
                };
            },
        },
    };
</script>
