<template>
    <div>
        <y-page-head
            :title="$t('profile.preferences.title')"
            icon="md-account-settings"
        >
            <template slot="actions">
                <y-button
                    color="blue"
                    form="main-form"
                    loading-on="submitting-form"
                    wide
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <div v-if="!$wait.is('loading-page')">
                <main>
                    <div class="row">
                        <div class="col-sm-8 col-center">
                            <y-panel>
                                <form
                                    id="main-form"
                                    @submit.prevent="submit(save)"
                                >
                                    <y-form
                                        v-if="form"
                                        v-model="model"
                                        :params="form"
                                    />
                                </form>
                            </y-panel>
                        </div>
                    </div>
                </main>
            </div>
        </y-loading>
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    // Components
    import { YForm } from '@deps';

    export default {
        name: 'ProfilePreferences',

        components: {
            YForm,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.profile.preferences')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('profile.preferences.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form : null,
                model: null,
            };
        },

        methods: {
            /**
             * Fetch grid
             *
             * @returns {*}
             */
            fetch() {
                return this.$services.Person.userPreferencesFetch().then((response) => {
                    this.form = response.data.metadata.form;
                    this.model = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save profile changes
             *
             * @returns {*}
             */
            save() {
                return this.$services.Person.userPreferencesSave(this.model).then((response) => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('profile.preferences.title') }));
                    const { token } = response.data.metadata;
                    if (token) {
                        this.$store.dispatch('auth/login', token).then(() => {
                            location.reload(); // eslint-disable-line no-restricted-globals
                        });
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };

</script>
