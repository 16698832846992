<template>
    <div>
        <y-page-head
            :title="$t('profile.notification.title')"
            icon="md-alert-circle-outline"
        >
            <template slot="actions">
                <y-button
                    color="blue"
                    loading-on="submitting-form"
                    wide
                    @click.native.prevent="save"
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-notifs')"
            height="calc( 100vh - 239px )"
        >
            <div v-if="notifs">
                <main>
                    <div class="row">
                        <div class="col-sm-8 col-center">
                            <y-panel>
                                <table class="table bordered mb0">
                                    <thead>
                                        <tr>
                                            <th>
                                                <y-form-field
                                                    v-model="query"
                                                    type="text"
                                                    class="mb0"
                                                    :placeholder="$t('fields.search')"
                                                    no-label
                                                />
                                            </th>
                                            <th class="ta-c">
                                                {{ $t('profile.notification.driver.browser') }}
                                            </th>
                                            <th class="ta-c">
                                                {{ $t('profile.notification.driver.database') }}
                                            </th>
                                            <th class="ta-c">
                                                {{ $t('profile.notification.driver.email') }}
                                            </th>
                                            <th class="ta-c">
                                                {{ $t('profile.notification.driver.sms') }}
                                            </th>
                                            <th class="ta-c">
                                                {{ $t('profile.notification.driver.pattern') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="query && !searchResult.length">
                                            <y-empty-state
                                                no-image
                                                height="100"
                                                class="mt10 mb0"
                                                :message="$t('profile.notification.message.search_no_result')"
                                            />
                                        </template>

                                        <template v-else-if="searchResult && searchResult.length">
                                            <tr
                                                v-for="(item, index) in searchResult"
                                                :key="index"
                                            >
                                                <td>
                                                    {{ item.title }}
                                                </td>
                                            </tr>
                                        </template>

                                        <template v-else-if="notifs">
                                            <tr
                                                v-for="(item, index) in notifs"
                                                :key="index"
                                            >
                                                <td>
                                                    {{ item.title }}
                                                </td>
                                                <td
                                                    v-for="(channel, i) in channels"
                                                    :key="i"
                                                >
                                                    <y-form-field
                                                        v-model="model.notifications[index][channel]"
                                                        type="checkbox"
                                                        color="green"
                                                        no-label
                                                        class="ta-c mb0"
                                                        :disabled="!item[`${channel}_preferable`]"
                                                    />
                                                </td>
                                            </tr>
                                        </template>

                                        <template v-else>
                                            <y-empty-state
                                                no-image
                                                height="100"
                                                class="mt10 mb0"
                                                :message="$t('profile.notification.message.empty')"
                                            />
                                        </template>
                                    </tbody>
                                </table>
                            </y-panel>
                        </div>
                    </div>
                </main>
            </div>
        </y-loading>
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import YEmptyState from '@deps/EmptyState';

    // Components
    import { YFormField } from '@deps';

    export default {

        name: 'NotificationPreferences',

        components: {
            YFormField,
            YEmptyState,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.profile.notification')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('profile.notification.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    notifications: [],
                },
                notifs      : null,
                channels    : ['browser', 'database', 'mail', 'sms', 'pattern'],
                test        : true,
                query       : null,
                searchResult: [],
            };
        },

        computed: {
            /**
             * List of notifs
             */
            notifsList() {
                return this.searchResult && this.searchResult.length ? this.searchResult : this.notifs;
            },
        },

        watch: {
            query: {
                /**
                 * Watch for search query
                 *
                 * @param newVal
                 */
                handler(newVal) {
                    if (newVal) {
                        this.searchResult = [];
                        Object.keys(this.notifs).forEach((key) => {
                            if (this.notifs[key].title.includes(this.query)) {
                                this.searchResult.push(this.notifs[key]);
                            }
                        });
                    }
                },
                deep: true,
            },
        },

        methods: {
            /**
             * Fetch Notifier Options
             */
            fetch() {
                return this.$services.Notifier.myNotifications().then((response) => {
                    this.$set(this, 'notifs', response.data.results);
                    this.createModel(response.data.results);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Create Filtered Model
             *
             * @param response
             */
            createModel(response) {
                Object.keys(response).forEach((key) => {
                    this.$set(this.model.notifications, `${key}`, {});
                    this.$set(this.model.notifications[key], 'hashid', response[key].hashid);
                    this.channels.forEach((item) => {
                        this.$set(this.model.notifications[key], `${item}`, response[key][item]);
                    });
                });
            },

            /**
             * Save the Notifications
             */
            save() {
                this.$wait.start('submitting-form');
                const params = {
                    ...this.model,
                };
                return this.$services.Notifier.userNotificationManagement(params).then(() => {
                    this.$toast.success(this.$t('profile.notification.message.success'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },

        },

    };

</script>
