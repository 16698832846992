<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-content-last-posts"
    >
        <template slot="functions">
            <y-button
                tag="router-link"
                :to="{ name: 'persons-profile-login-history' }"
                size="sm"
            >
                {{ $t('button.more') }}
            </y-button>
            <y-button
                v-if="!isManage"
                :key="5"
                size="sm"
                icon="md-reload"
                class="color-green"
                @click="fetch"
            />
        </template>

        <y-loading
            :active="isLoading"
            height="100px"
        >
            <table class="table bordered mb0">
                <thead>
                    <tr>
                        <th>{{ $t('profile.history.date') }}</th>
                        <th>{{ $t('profile.history.ip') }}</th>
                        <th>{{ $t('profile.history.status') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="row in model"
                        :key="row.id"
                        :class="[row.passed ? 'green' : 'red']"
                    >
                        <td class="ltr">
                            {{ row.created_at | date('YYYY/MM/DD - HH:mm:ss', $i18n.locale) | digits }}
                        </td>
                        <td>{{ row.ip }}</td>
                        <td>
                            <div
                                v-if="row.passed"
                                class="label green"
                            >
                                {{
                                    $t('profile.history.successful') }}
                            </div>
                            <div
                                v-else
                                class="label red"
                            >
                                {{ $t('profile.history.unsuccessful') }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </y-loading>
    </y-panel>
</template>

<script>

    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'PersonsLoginHistoryWidget',

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {

            /**
             * Fetch user login history
             */
            fetch() {
                this.startLoading();
                const params = {
                    limit    : 3,
                    including: [
                        'ip',
                        'passed',
                        'created_at',
                    ],
                };
                return this.$services.Person.loginHistory(params).then((response) => {
                    this.model = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.stopLoading();
                    this.updated();
                });
            },

        },

    };
</script>
