/*-----------------------------------------------------------------
- Widget mixin
-----------------------------------------------------------------*/
import YEmptyState from '@deps/EmptyState';

export default {

    components: {
        YEmptyState,
    },

    /**
     * @inheritDoc
     */
    data() {
        return {
            loading: `widget-loading-${this.element.id}`,
            model  : null,
        };
    },

    props: {
        /**
         * Id of the widget to refresh
         */
        refreshId: String,
    },

    watch: {
        refreshId: {
            /**
             * Refresh Widget if id presented
             *
             * @param newVal
             */
            handler(newVal) {
                if (newVal === this.element.id) {
                    if (typeof this.refresh === 'function') {
                        this.refresh();
                    } else if (typeof this.fetch === 'function') {
                        this.fetch();
                    }
                    this.$emit('done-refresh');
                }
            },
            deep: true,
        },
    },

    computed: {

        /**
         * Check is loading
         */
        isLoading() {
            return this.$wait.is(this.loading);
        },

        /**
         * Check if widgets are in manage mode
         */
        isManage() {
            return this.$store.getters['dashboard/manage'];
        },

    },

    /**
     * @inheritDoc
     */
    created() {
        this.startLoading();
    },

    /**
     * @inheritDoc
     */
    mounted() {
        this.updated();
    },

    /**
     *
     */
    methods: {

        /**
         * Emit updated
         */
        updated() {
            setTimeout(() => {
                this.$emit('updated');
            }, 100);
        },

        /**
         * Start loading
         */
        startLoading() {
            this.$wait.start(this.loading);
        },

        /**
         * Stop loading
         */
        stopLoading() {
            this.$wait.end(this.loading);
        },

    },

};
