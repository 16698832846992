<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-user-finder"
    >
        <y-loading
            :active="isLoading"
            height="100px"
        >
            <div class="container">
                <!-- Form -->
                <form
                    id="user-finder-form"
                    class="finder-form"
                    @submit.prevent="searchUser"
                >
                    <y-form-field
                        v-model="query"
                        type="text"
                        :placeholder="$t('dashboard.widgets.user_finder.form.placeholder')"
                        no-label
                        name="search"
                        class="mb0"
                    />

                    <y-button
                        color="blue"
                        loading-on="loading-user-result"
                        form="user-finder-form"
                    >
                        {{ $t('dashboard.widgets.user_finder.form.button') }}
                    </y-button>
                </form>

                <!-- Results - Has Results -->
                <y-loading
                    v-if="results"
                    :active="$wait.is('loading-result')"
                    height="100px"
                >
                    <div
                        v-if="results && results.length > 0"
                        class="finder-result"
                    >
                        <!-- Results - Items -->
                        <div
                            v-for="(item, index) in results.slice(0, 5)"
                            :key="index"
                            class="finder-result-item"
                            :class="{'last': index == 4 || index == results.length - 1}"
                        >
                            <div class="finder-result-item-text">
                                <span class="avatar">
                                    <i class="icon md-account-circle" />
                                </span>
                                <div>
                                    <span class="finder-result-item-title">{{ item.name_full }}</span>
                                    <span class="finder-result-item-subtitle">{{ item.email }}</span>
                                </div>
                            </div>
                            <y-button
                                v-if="item.can_view"
                                tag="router-link"
                                :to="{ name: 'persons-users-login-history', params: {id: item.id} }"
                                size="xs"
                            >
                                {{ $t('dashboard.widgets.user_finder.result.view_profile') }}
                            </y-button>
                        </div>

                        <!-- Results - Footer -->
                        <div
                            v-if="results && results.length > 5"
                            class="finder-result-footer"
                        >
                            <!-- <span class="finder-result-footer-title">{{ $t('dashboard.widgets.user_finder.result.total') }}{{ results.length | digits }}</span> -->
                            <y-button
                                size="xs"
                                @click="moreResultLink"
                            >
                                {{ $t('dashboard.widgets.user_finder.result.more_result') }}
                            </y-button>
                        </div>
                    </div>

                    <!-- Results - No Result -->
                    <y-empty-state
                        v-else
                        height="250"
                        class="mb0"
                        :title="$t('dashboard.widgets.user_finder.result.no_result')"
                    />
                </y-loading>
            </div>
        </y-loading>
    </y-panel>
</template>

<script>
    import YFormField from '@deps/form/FormField';
    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'UserFinderWidget',

        components: {
            YFormField,
        },

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                query  : '',
                results: null,
                loading: false,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.stopLoading();
        },

        methods: {

            /**
             * Search
             */
            searchUser() { // eslint-disable-line
                if (!this.query) {
                    return false;
                }
                this.$wait.start('loading-user-result');
                this.updated();
                this.results = null;
                const params = {
                    id    : this.element.id,
                    search: this.query,
                };
                this.$services.Panel.widgetContent(params).then((response) => {
                    this.results = response.data.results;
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                }).finally(() => {
                    this.$wait.end('loading-user-result');
                    this.updated();
                });
            },

            /**
             * More Result Link
             */
            moreResultLink() {
                this.$router.push({
                    name  : 'persons-users-list',
                    params: {
                        role: 'all',
                    },
                    query: {
                        search: this.query,
                    },
                });
            },

        },

    };
</script>
