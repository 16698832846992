<template>
    <main>
        <!-- Avatar -->
        <div
            v-if="hasAvatar && settings"
            class="row mb10"
        >
            <div class="col-sm-8 col-center p0">
                <div class="d-f justify-content-between align-items-center">
                    <div class="persons-profile__container">
                        <y-profile-avatar
                            v-if="editAvatar || !avatarThumb"
                            :element="settings.avatarImg"
                            :avatar-img="avatarImage"
                            class="persons-profile__avatar"
                            :class="{ 'removed': removed, 'has-avatar': newAvatar || editAvatar }"
                            @success="addAvatarFile"
                            @remove="removeAvatarFile"
                        />
                        <template v-else>
                            <div
                                v-if="!removed && hasAvatar"
                                class="persons-profile__avatar-img"
                            >
                                <img :src="avatarThumb">
                            </div>
                        </template>

                        <y-button
                            v-if="showEditButton"
                            class="persons-profile__edit-btn color-blue"
                            size="sm"
                            :icon="editAvatar ? 'md-chevron-left' : 'md-pencil'"
                            @click="toggleEditAvatar"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Profile -->
        <div class="row mb5 justify-content-center">
            <div class="col-sm-8 p0">
                <y-form
                    v-model="model"
                    :params="settings.form"
                />
            </div>
        </div>

        <!-- Addresses -->
        <div
            v-if="hasLocations && (settings.locations && settings.locations.length)"
            class="row mb5"
        >
            <div class="col-sm-8 col-center p0">
                <span class="persons-user-search__label">
                    {{ $t('profile.edit.addresses') }}
                </span>
                <div class="table-wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th
                                    v-for="column in tableHeader"
                                    :key="column.id"
                                >
                                    {{ column.title }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(row, index) in settings.locations"
                                :key="row.id"
                            >
                                <td class="invoice-block-value">
                                    {{ (index + 1) | digits }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.title }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ pc(row) }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.address }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.tel }}
                                </td>
                                <td
                                    width="150"
                                    class="invoice-block-value"
                                >
                                    <y-dropdown>
                                        <template slot="toggle">
                                            <y-button size="xs">
                                                {{ $t('button.action') }}
                                            </y-button>
                                        </template>

                                        <a
                                            href="#"
                                            @click.prevent="openGlobalModal('Persons-LocationEditModal', { id: row.id })"
                                        >
                                            {{ $t('button.edit') }}
                                        </a>
                                        <a
                                            href="#"
                                            @click.prevent="openGlobalModal('Persons-SetDefaultLocationModal', { id: row.id, _title: row.title })"
                                        >
                                            {{ $t('profile.edit.make_default') }}
                                        </a>
                                        <a
                                            href="#"
                                            @click.prevent="openGlobalModal('Panel-TypicalTrashModal', { id: row.id, model: 'PersonsLocation', _title: row.title })"
                                        >
                                            {{ $t('button.delete') }}
                                        </a>
                                    </y-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import GlobalMixin from '@/mixins/Global';

    import YProfileAvatar from '@/modules/persons/components/profile/Avatar';
    import { YForm, YDropdown } from '@deps';

    export default {
        name: 'ProfileEditor',

        components: {
            YProfileAvatar,
            YForm,
            YDropdown,
        },

        mixins: [GlobalMixin],


        props: {
            value            : Object,
            settings         : Object,
            hasLocations     : Boolean,
            hasAvatar        : Boolean,
            hasAvatarResource: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model      : this.value || {},
                editAvatar : false,
                removed    : false,
                newAvatar  : false,
                tableHeader: [
                    {
                        title: this.$t('fields.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('fields.title'),
                        id   : 'title',
                    },
                    {
                        title: `${this.$t('fields.province')} - ${this.$t('fields.city')}`,
                        id   : 'division',
                    },
                    {
                        title: this.$t('fields.address'),
                        id   : 'address',
                    },
                    {
                        title: this.$t('fields.tel'),
                        id   : 'tel',
                    },
                    {
                        title: this.$t('button.action'),
                        id   : 'action',
                    },
                ],
            };
        },

        computed: {
            /**
             * Check if edit icon should be shown
             */
            showEditButton() {
                let show = false;
                if (this.editAvatar) {
                    show = this.avatarThumb ? this.avatarThumb : false;
                }
                if (this.hasAvatar && this.hasAvatarResource) {
                    show = true;
                }
                if (this.editAvatar && this.removed) {
                    show = false;
                }
                if (this.editAvatar && this.removed) {
                    show = false;
                }
                return show;
            },

            /**
             * Return the avatar image
             */
            avatarImage() {
                return this.hasAvatar
                    && this.settings
                    && this.settings.avatarImg
                    && this.settings.avatarOptions
                    && this.settings.avatarOptions.resource && this.settings.avatarOptions.resource.length ? this.settings.avatarOptions.resource : [];
            },

            /**
             * Avatar Thumbnail Image
             */
            avatarThumb() {
                return this.hasAvatar
                    && this.settings
                    && this.settings.avatarImg
                    && this.settings.avatarOptions
                    && this.settings.avatarOptions.resource && this.settings.avatarOptions.resource.length && this.settings.avatarOptions.resource[0].versions ? this.settings.avatarOptions.resource[0].versions.panel_thumb : null;
            },
        },

        methods: {
            /**
             * Toggle editAvatar state
             */
            toggleEditAvatar() {
                this.editAvatar = !this.editAvatar;
            },

            /**
             * Handle successful avatar upload
             *
             * @param {object} file
             */
            addAvatarFile(file) {
                this.newAvatar = true;
                this.removed = false;
                this.model.avatar = [file.id];
                this.avatarImg = [file];
            },

            /**
             * Handle avatar file removal
             */
            removeAvatarFile() {
                this.newAvatar = false;
                this.removed = true;
                this.avatarImg = [];
                this.model.avatar = null;
            },

            /**
             * Return the combination of Province and City
             *
             * @param location
             */
            pc(location) {
                if (location.province_name || location.city_name) {
                    return `${location.province_name} - ${location.city_name}`;
                }
                return '-';
            },
        },
    };

</script>
