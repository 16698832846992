<template>
    <div>
        <y-page-head
            :title="$t('profile.edit.title')"
            icon="md-account-edit"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <!-- Save -->
                <y-button
                    color="blue"
                    loading-on="submitting-form"
                    @click.prevent.native="save"
                >
                    {{ $t('button.save') }}
                </y-button>

                <!-- Google 2FA -->
                <template v-if="$settings('two_factor_authentication.enabled', false) * 1">
                    <y-button
                        v-if="!$store.getters['auth/twoFA']"
                        @click.native="$refs.TwoFactorActivateModal.open()"
                    >
                        {{ $t('profile.google.activate') }}
                    </y-button>

                    <y-dropdown
                        v-else
                        position="bottom-left"
                    >
                        <template slot="toggle">
                            <y-button>
                                {{ $t('profile.google.title') }}
                            </y-button>
                        </template>

                        <a
                            href="#"
                            @click.prevent="$refs.BackupCodesModal.open()"
                        >
                            {{ $t('fields.backup_codes') }}
                        </a>
                        <a
                            v-if="settings && !settings.is_required_2fa"
                            href="#"
                            @click.prevent="$refs.TwoFactorDeactivateModal.open()"
                        >
                            {{ $t('button.deactivate') }}
                        </a>
                    </y-dropdown>
                </template>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <y-profile-editor
                v-model="model"
                :settings="settings"
                :has-locations="hasLocations"
                :has-avatar="hasAvatar"
                :has-avatar-resource="hasAvatarResource"
            />
            <!-- TwoFactorModal -->
            <y-two-factor-activate-modal
                ref="TwoFactorActivateModal"
                @enabled="$refs.BackupCodesModal.open($event)"
            />
            <y-two-factor-deactivate-modal ref="TwoFactorDeactivateModal" />
            <y-two-factor-backup-codes-modal ref="BackupCodesModal" />
        </y-loading>
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import GlobalMixin from '@/mixins/Global';

    // Components
    import { YDropdown } from '@deps';
    import YTwoFactorActivateModal from '@/modules/persons/components/profile/TwoFactorActivateModal';
    import YTwoFactorDeactivateModal from '@/modules/persons/components/profile/TwoFactorDeactivateModal';
    import YTwoFactorBackupCodesModal from '@/modules/persons/components/profile/TwoFactorBackupCodesModal';
    import YProfileEditor from '@/modules/persons/components/profile/Editor';

    export default {

        name: 'ProfileEdit',

        components: {
            YDropdown,
            YTwoFactorActivateModal,
            YTwoFactorDeactivateModal,
            YTwoFactorBackupCodesModal,
            YProfileEditor,
        },

        mixins: [PageMixin, FormMixin, GlobalMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.profile.edit')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('profile.edit.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model            : null,
                settings         : null,
                hasAvatar        : false,
                hasAvatarResource: false,
            };
        },

        computed: {

            /**
             * Check if the form should has location list
             */
            hasLocations() {
                return !!(this.settings && this.settings.locations);
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            // Check events
            this.$root.$on('refreshGrid', () => {
                this.fetch();
            });
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            this.$root.$off('refreshGrid');
        },

        methods: {
            /**
             * Fetch grid
             *
             * @returns {*}
             */
            fetch() {
                return this.$services.Person.userProfileFetch().then((response) => {
                    const { results, metadata } = response.data;
                    const hasAvatar = !!(Array.isArray(results.avatar));

                    this.hasAvatar = hasAvatar;
                    this.hasAvatarResource = !!(Array.isArray(results.avatar) && results.avatar.length);

                    const model = {
                        ...results,
                    };
                    const settings = {
                        ...metadata,
                    };

                    settings.avatarImg = hasAvatar ? results.avatar : [];
                    if (hasAvatar && results.avatar.length) {
                        model.avatar = [results.avatar[0]];
                        settings.avatarOptions = metadata.avatar ? metadata.avatar : null;
                    }

                    this.model = model;
                    this.settings = settings;

                    this.registerGlobalModals(response.data.metadata.modals);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save profile changes
             *
             * @returns {*}
             */
            save() {
                this.$wait.start('submitting-form');
                return this.$services.Person.userEditProfile(this.model).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('profile.edit.title') }));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },
        },

    };

</script>
