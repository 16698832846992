<template>
    <y-panel-grid :service="$services.Person.organizationsGrid" />
</template>


<script>

    import GridPageMixin from '@/mixins/GridPage';

    export default {
        name: 'PersonsOrganizationsList',

        mixins: [GridPageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.persons.organizations')),
            ];
        },
    };
</script>
