<template>
    <y-modal
        ref="Modal"
        :title="$t('profile.google.deactivate')"
        size="xs"
    >
        <div slot="body">
            <p>{{ $t('profile.google.deactivate_text') }}</p>

            <form
                id="twofa-form"
                @submit.prevent="submit(save)"
            >
                <y-form-field
                    v-model="model"
                    type="number"
                    name="google_code"
                    validation="required|digits:6"
                />
            </form>
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    form="twofa-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.deactivate') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YFormField } from '@deps';

    export default {
        name: 'TwoFactorActivateModal',

        components: {
            YModal,
            YFormField,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: null,
            };
        },

        methods: {

            /**
             * Save form
             */
            save() {
                const params = {
                    code: this.model,
                };
                return this.$services.Person.disableTwoFactorAuthentication(params).then((response) => {
                    this.$toast.success(this.$t('profile.google.deactivate_success'));
                    this.$store.commit('auth/login', response.data.metadata.token);
                    this.$emit('done');
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open modal
             */
            open() {
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.model = null;
            },

        },
    };
</script>
