<template>
    <div class="widget widget-counter">
        <section
            class="panel tile"
            :class="[element.color, element.style, { alt: element.alt }]"
        >
            <article>
                <i
                    class="tile-icon icon"
                    :class="[element.icon]"
                />
                <h5>{{ element.title }}</h5>
                <h2>{{ element.count | digits }}</h2>
            </article>
        </section>
        <div class="i" />
    </div>
</template>

<script>
    export default {

        name: 'WidgetCounter',

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

    };
</script>
