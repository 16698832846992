<template>
    <y-panel-grid :service="$services.Panel.grid" />
</template>

<script>
    import GridPageMixin from '@/mixins/GridPage';

    export default {
        name: 'Grid',

        mixins: [
            GridPageMixin,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
            ];
        },
    };

</script>
