<template>
    <div>
        <y-page-head
            :title="$t('profile.password.title')"
            icon="md-key"
        >
            <template slot="actions">
                <y-button
                    color="blue"
                    form="main-form"
                    loading-on="submitting-form"
                    wide
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <main>
            <y-loading
                :active="$wait.is('loading-form')"
                height="300px"
            >
                <div class="row">
                    <div class="col-sm-5 col-center">
                        <y-panel>
                            <article class="p10">
                                <form
                                    id="main-form"
                                    :class="[{disabled: $wait.is('submitting-form')}]"
                                    @submit.prevent="submit(changePassword)"
                                >
                                    <y-form
                                        :key="keyHelper"
                                        v-model="model"
                                        :params="form"
                                    />
                                </form>
                            </article>
                        </y-panel>
                    </div>
                </div>
            </y-loading>
        </main>
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import { YForm } from '@deps';

    export default {

        name: 'LoginHistory',

        components: {
            YForm,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.profile.password')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('profile.password.title'),
            };
        },

        /**
         * @inheritDoc
         */
        beforeRouteLeave(to, from, next) {
            const forceChangePassword = this.$store.getters['auth/forceChangePassword'];
            if (forceChangePassword && to.name !== 'auth-login') {
                next(false);
            } else {
                next();
            }
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form     : [],
                model    : null,
                keyHelper: generateId(),
            };
        },

        methods: {

            /**
             * Fetch user login history
             */
            fetch() {
                this.$wait.start('loading-form');
                return this.$services.Person.changePasswordForm().then((response) => {
                    this.form = response.data.metadata.form;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-form'));
            },

            /**
             * Change password
             */
            changePassword() {
                return this.$services.Person.changePassword(this.model).then(() => {
                    this.model = {};
                    this.keyHelper = generateId();
                    this.$toast.success(this.$t('messages.change_password.success'));
                    this.$store.commit('auth/setForcePasswordChange', false);
                }).catch((error) => {
                    this.handleError(error, this.$t('messages.change_password.error'));
                });
            },

        },

    };
</script>
