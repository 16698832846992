<template>
    <y-modal
        ref="Modal"
        :title="$t('profile.google.activate')"
        size="xs"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('loading-post')"
                height="200px"
            >
                <template v-if="!codes">
                    <!-- QR code -->
                    <div class="ta-c mb30">
                        <img
                            :src="qr"
                            alt="QR Code"
                        >
                    </div>

                    <p>{{ $t('profile.google.text') }}</p>

                    <form
                        id="faq-form"
                        @submit.prevent="submit(save)"
                    >
                        <y-form-field
                            v-model="model"
                            type="number"
                            name="google_code"
                            validation="required|length:6"
                        />
                    </form>
                </template>

                <template v-else>
                    <p v-html="$t('profile.google.activate_backup_codes_desc')" />

                    <y-row class="mt30">
                        <y-col
                            v-for="code in codes"
                            :key="code"
                            xs="6"
                        >
                            <div class="code-item">
                                {{ code | digits }}
                            </div>
                        </y-col>
                    </y-row>
                </template>
            </y-loading>
        </div>

        <template
            v-if="!$wait.is('loading-post') && !codes"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    key="cancel-btn"
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    form="faq-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>

        <template
            v-if="!$wait.is('loading-post') && codes"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    key="close-btn"
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.close') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YFormField } from '@deps';

    export default {
        name: 'TwoFactorActivateModal',

        components: {
            YModal,
            YFormField,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                qr   : null,
                model: null,
                codes: null,
            };
        },

        methods: {

            /**
             * Save form
             */
            fetch() {
                this.$wait.start('loading-post');

                this.$services.Person.generateTwoFactorAuthentication().then((response) => {
                    this.qr = response.data.results.token;
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => this.$wait.end('loading-post'));
            },

            /**
             * Save form
             */
            save() {
                const params = {
                    code : this.model,
                    class: 'google',
                };
                return this.$services.Person.enableTwoFactorAuthentication(params).then((response) => {
                    this.$toast.success(this.$t('profile.google.activate_success'));
                    this.$store.commit('auth/login', response.data.metadata.token);
                    this.codes = response.data.metadata.backup_codes;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open modal
             */
            open() {
                this.$refs.Modal.open();
                this.fetch();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.qr = null;
                this.model = null;
                this.codes = null;
            },

        },
    };
</script>
