/*-----------------------------------------------------------------
- Profile Tabs Mixin
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';
import { date } from '@nodes/helpers/date';
import { digits } from '@nodes/helpers/number';
import YPagination from '@deps/Pagination';
import YEmptyState from '@deps/EmptyState';

export default {
    name: 'ProfileTabsMixin',

    components: {
        YEmptyState,
        YPagination,
    },

    props: {
        /**
         * Data of the tab
         */
        data: Object,

        /**
         * Check if tab is changed
         */
        activeTab: String,
    },

    /**
     * @inheritdoc
     */
    data() {
        return {
            content        : null,
            paginationModel: null,
        };
    },

    computed: {
        /**
         * The Endpoint of the tab
         */
        endpoint() {
            return this.data.endpoint;
        },
    },

    /**
     * @inheritdoc
     */
    created() {
        if (this.endpoint) {
            this.fetchTabContent(this.endpoint);
        }
    },

    methods: {
        /**
         * Fetch Tab Content
         * 
         * @param {object} api - Object of the API
         */
        fetchTabContent(api) {
            this.$wait.start('pagination-loading');
            const endpoint = api || this.endpoint;
            const method = (endpoint.method || 'get').toLowerCase();
            const version = endpoint.version || 1;
            const { name } = endpoint;

            const { params } = endpoint;
            if (params.paginated) {
                if (this.$route.query.page) {
                    params.page = this.$route.query.page;
                } else {
                    params.page = 1;
                }
            }

            Resource[method](name, [params, version]).then((response) => {
                this.$set(this, 'content', response.data);
                this.paginationModel = response.data.metadata;
            }).catch((error) => {
                this.handleError(error);
            }).finally(() => {
                this.$wait.end('pagination-loading');
            });
        },

        /**
         * Row number
         * 
         * @param {number} row - Index of the row
         */
        rowNumber(row) {
            if (this.paginationModel && (this.paginationModel.current_page || this.paginationModel.per_page)) {
                return (this.paginationModel.current_page - 1) * this.paginationModel.per_page + row + 1;
            }
            return row + 1;
        },

        /**
         * Date helper for using in inline condition
         * 
         * @param {Date} value - Date
         */
        dateHelper(value) {
            return date(value, 'YYYY/MM/DD', this.$i18n.locale);
        },

        /**
         * Digit helper for using in inline condition
         * 
         * @param {number} value - Number
         */
        digitsHelper(value) {
            return digits(value, this.$i18n.locale);
        },
    },

};
