<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-user-chart"
    >
        <template slot="functions">
            <y-button
                v-if="!isManage"
                :key="5"
                size="sm"
                icon="md-reload"
                class="color-green"
                @click="fetch"
            />
        </template>

        <y-loading
            :active="isLoading"
            height="250px"
        >
            <y-chart
                :series="series"
                :tooltip-formatter="tooltip"
                :x-axis-label="xAxisLabel"
                :y-axis-label="yAxisLabel"
                :options="options"
            />
        </y-loading>
    </y-panel>
</template>

<script>

    import { date } from '@nodes/helpers/date';
    import { digits } from '@nodes/helpers/number';
    import YChart from '@deps/Chart';
    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'UserRegistrationChartWidget',

        components: {
            YChart,
        },

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                role   : '',
                rawData: [],
            };
        },

        computed: {

            /**
             * Generate xAxis Label
             */
            xAxisLabel() {
                return Object.keys(this.rawData).map((i) => date(i, 'DD MMMM', this.$i18n.locale));
            },

            /**
             * Generate yAxis Label
             */
            yAxisLabel() {
                return ((params) => `${digits(params, this.$i18n.locale)}`);
            },

            /**
             * Tooltip Formatter
             */
            tooltip() {
                return ((params) => `${params[0].axisValue}: ${digits(params[0].value, this.$i18n.locale)}`);
            },

            /**
             * Chart Options
             */
            options() {
                return {
                    grid: {
                        width: '95%',
                        left : '5%',
                    },
                    xAxis: {
                        axisLabel: {
                            interval: 0,
                            rotate  : 45,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        min : 0,
                    },
                };
            },

            /**
             * Series of the widget
             */
            series() {
                const data = [];
                const dataArray = Object.entries(this.rawData).map((i) => i);
                dataArray.forEach((item) => {
                    data.push(item[1]);
                });

                return [
                    {
                        type      : 'line',
                        smooth    : true,
                        symbol    : 'circle',
                        symbolSize: 8,
                        itemStyle : {
                            color: '#007AFF',
                        },
                        data,
                    },
                ];
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.fetch();
        },

        methods: {

            /**
             * Fetch user register data
             */
            fetch() {
                this.locale = this.$i18n.locale;
                this.startLoading();
                const params = {
                    id: this.element.id,
                };
                return this.$services.Panel.widgetContent(params).then((response) => {
                    this.role = response.data.results.role;
                    this.rawData = response.data.results.counts;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.stopLoading();
                    this.updated();
                });
            },

        },

    };
</script>
