<template>
    <div class="paginate ta-c">
        <ul
            class="pagination"
            :class="['pagination-'+ mode]"
        >
            <li
                v-if="current === 1"
                class="disabled"
                aria-disabled="true"
            >
                <span aria-hidden="true">
                    <i
                        v-if="prevIcon"
                        class="icon"
                        :class="prevIcon"
                    />
                    {{ prev }}
                </span>
            </li>
            <li v-else>
                <router-link :to="routeComputer(current - 1)">
                    <i
                        v-if="prevIcon"
                        class="icon"
                        :class="prevIcon"
                    />
                    {{ prev }}
                </router-link>
            </li>

            <template v-for="(page, index) in paginator">
                <li
                    v-if="typeof page === 'string'"
                    :key="'button-'+ index"
                    :class="{active: page === current}"
                >
                    <span>
                        {{ page }}
                    </span>
                </li>

                <li
                    v-else
                    :key="'button-'+ index"
                    :class="{active: page === current}"
                >
                    <router-link :to="routeComputer(page)">
                        {{ page | digits }}
                    </router-link>
                </li>
            </template>


            <li
                v-if="current === model.last_page"
                class="disabled"
                aria-disabled="true"
            >
                <span aria-hidden="true">
                    {{ next }}
                    <i
                        v-if="nextIcon"
                        class="icon"
                        :class="nextIcon"
                    />
                </span>
            </li>
            <li v-else>
                <router-link :to="routeComputer(current + 1)">
                    {{ next }}
                    <i
                        v-if="nextIcon"
                        class="icon"
                        :class="nextIcon"
                    />
                </router-link>
            </li>
        </ul>
    </div>
</template>


<script>

    /**
     * Pagination Component
     */
    export default {
        name: 'Pagination',

        props: {
            /**
             * Pagination Object containing fetched pagination data
             */
            model: {
                type    : Object,
                required: true,
            },

            /**
             * Pagination Style:
             * `1, 2, 3, ..., 9`
             */
            mode: {
                type   : String,
                default: '0',
            },

            nextIcon: {
                type   : String,
                default: null,
            },

            prevIcon: {
                type   : String,
                default: null,
            },

            noText: {
                type   : Boolean,
                default: false,
            },

            nextContent: {
                type   : String,
                default: null,
            },

            prevContent: {
                type   : String,
                default: null,
            },
        },

        computed: {
            /**
             * Gets a list of pagination items
             *
             * @returns {*}
             */
            paginator() {
                return this.setPaginator(this.current, this.model.last_page);
            },

            /**
             * Checks if link is using params for pagination
             *
             * @returns {boolean}
             */
            useParams() {
                return !!(this.$route.params && 'page' in this.$route.params);
            },

            /**
             * Gets current page number
             *
             * @returns {number}
             */
            current() {
                if (this.useParams) {
                    // eslint-disable-next-line radix
                    return this.$route.params.page ? parseInt(this.$route.params.page) : 1;
                }

                if (this.$route.query.page) {
                    // eslint-disable-next-line radix
                    return parseInt(this.$route.query.page);
                }

                return 1;
            },

            /**
             * Return next content based on the nextContent prop
             *
             * @returns {string|null}
             */
            next() {
                let content = this.nextContent ? this.nextContent : this.$t('general.pagination.next');
                if (this.noText) {
                    content = null;
                }
                return content;
            },

            /**
             * Return next content based on the nextContent prop
             *
             * @returns {string|null}
             */
            prev() {
                let content = this.prevContent ? this.prevContent : this.$t('general.pagination.prev');
                if (this.noText) {
                    content = null;
                }
                return content;
            },
        },

        watch: {
            /**
             * Emit change event when current page changes
             *
             * @param newVal
             */
            current(newVal) {
                /**
                 * Pagination Change Event
                 *
                 * @type {number}
                 */
                this.$emit('change', newVal);
            },
        },

        methods: {

            /**
             * Decides whether pagination needs "..." or not
             *
             * @param current
             * @param last
             * @returns {*}
             */
            setPaginator(current, last) {
                if (last <= 10) {
                    return this.setRange(last);
                }
                return this.setRangeWithDots(current, last, 3);
            },

            /**
             * Gets a list of pagination numbers
             *
             * @param last
             * @returns {*}
             */
            setRange(last) {
                const range = [];

                for (let i = 1; i <= last; i += 1) {
                    range.push(i);
                }

                return range;
            },

            /**
             * Gets a list of pagination numbers and "..."
             *
             * @param current
             * @param last
             * @param margin
             * @returns {*}
             */
            setRangeWithDots(current, last, margin) {
                const rangeWithDots = [];

                if (current >= margin) {
                    rangeWithDots.push(1);
                    rangeWithDots.push('...');
                }

                if (current < margin) {
                    for (let i = 1; i <= margin; i += 1) {
                        if (i <= margin) {
                            rangeWithDots.push(i);
                        }
                    }
                } else if (current > last - margin + 1) {
                    rangeWithDots.push(last - 2);
                    rangeWithDots.push(last - 1);
                    rangeWithDots.push(last);
                } else {
                    rangeWithDots.push(current - 1);
                    rangeWithDots.push(current);
                    rangeWithDots.push(current + 1);
                }

                if (last - current >= margin) {
                    rangeWithDots.push('...');
                }

                if (current < last - 1) {
                    rangeWithDots.push(last);
                }

                return rangeWithDots;
            },

            /**
             * Computes route object
             *
             * @param page
             * @returns {{query: {page: *}}|{params: {page: *}}}
             */
            routeComputer(page) {
                if (this.useParams) {
                    return {
                        ...this.$route,
                        params: {
                            ...this.$route.params,
                            page,
                        },
                    };
                }

                return {
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        page,
                    },
                };
            },
        },
    };
</script>
