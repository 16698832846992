<template>
    <div>
        <y-button
            v-if="!isManage"
            :key="5"
            size="sm"
            icon="md-reload"
            class="widget-refresh-btn"
            @click="refresh"
        />
        <section
            class="panel tile widget-user-finder"
            :class="element.color"
        >
            <y-loading
                :active="$wait.is(loadingId)"
                height="139px"
            >
                <article>
                    <i class="tile-icon icon md-poll" />
                    <h5>
                        {{ element.title }}
                    </h5>
                    <h2 class="mb0">
                        {{ total.count | digits }}
                    </h2>
                    <h5 class="mb0">
                        {{ total.role_name }}
                    </h5>
                </article>
            </y-loading>
        </section>
    </div>
</template>

<script>

    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'UserInfoWidget',

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                total    : 0,
                loadingId: `loading-${this.element.id}`,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.getTotal();
        },

        methods: {

            /**
             * User for refresh widget
             */
            refresh() {
                this.getTotal();
            },

            /**
             * Get total user
             */
            getTotal() { // eslint-disable-line
                this.$wait.start(this.loadingId);
                const params = {
                    id: this.element.id,
                };
                this.$services.Panel.widgetContent(params).then((response) => {
                    this.total = response.data.results;
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                }).finally(() => {
                    this.$wait.end(this.loadingId);
                });
            },

        },

    };
</script>
