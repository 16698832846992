<template>
    <y-modal
        ref="modal"
        class="settings-modal"
        :title="title"
        @close="reset"
    >
        <template slot="body">
            <y-loading
                :active="$wait.is('loading-setting')"
                height="200px"
            >
                <!-- Show setting form -->
                <form
                    id="modal-form"
                    @submit.prevent="save"
                >
                    <y-form
                        v-model="model"
                        :params="form"
                    />
                </form>
            </y-loading>
        </template>

        <template
            v-if="!$wait.is('loading-setting')"
            slot="footer"
        >
            <div class="d-f justify-content-between w-100 flex-wrap">
                <div>
                    <y-button
                        :class="{active: resetCollapseOpen}"
                        @click.prevent="toggleCollapse"
                    >
                        {{ $t('settings.reset_settings.button') }}
                    </y-button>
                </div>
                <div>
                    <y-button
                        v-if="!resetCollapseOpen"
                        class="ml5"
                        @click="close"
                    >
                        {{ $t('button.cancel') }}
                    </y-button>
                    <y-button
                        color="green"
                        loading-on="saving-setting"
                        form="modal-form"
                        :disabled="$wait.is('resetting-setting')"
                    >
                        {{ $t('button.save') }}
                    </y-button>
                </div>
                <div :class="resetClasses">
                    <hr>
                    <y-form-alert
                        type="warning"
                        :content="$t('settings.reset_settings.title')"
                    />
                    <div class="d-f justify-content-end">
                        <y-button
                            class="ml5"
                            @click="close"
                        >
                            {{ $t('button.cancel') }}
                        </y-button>
                        <y-button
                            loading-on="resetting-setting"
                            color="orange"
                            @click.prevent="resetSettings"
                        >
                            {{ $t('button.reset') }}
                        </y-button>
                    </div>
                </div>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal, YForm } from '@deps';
    import { YFormAlert } from '@deps/form/elements/Alert';

    export default {

        name: 'SettingModal',

        components: {
            YForm,
            YModal,
            YFormAlert,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form : null,
                title: null,
                slug : null,
                model: null,

                resetCollapseOpen: false,
            };
        },

        computed: {
            /**
             * Reset collapse classes
             */
            resetClasses() {
                const classes = ['reset-collapse mt10 w-100'];

                if (this.resetCollapseOpen) {
                    classes.push('reset-collapse--open');
                }

                return classes;
            },
        },

        methods: {

            /**
             * Open setting modal
             *
             * @param slug
             * @param title
             */
            open(slug, title) {
                this.$refs.modal.open();
                this.slug = slug;
                this.title = title;
                this.fetchModal();
            },

            /**
             * Close setting modal
             */
            close() {
                this.$refs.modal.close();
            },

            /**
             * Reset forms
             */
            reset() {
                this.slug = null;
                this.model = null;
                this.form = null;
                this.title = null;
                this.resetCollapseOpen = false;
            },

            /**
             * Fetch Form Modal
             */
            fetchModal() {
                this.$wait.start('loading-setting');
                this.$services.Setting.fetch({ slug: this.slug }).then((response) => {
                    this.form = response.data.metadata.form;
                    this.model = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-setting'));
            },

            /**
             * Save setting
             */
            save() {
                this.$wait.start('saving-setting');
                this.$services.Setting.set({ slug: this.slug, ...this.model }).then(async (response) => {
                    const payload = {
                        slug : this.slug,
                        value: response.data.metadata.new_value,
                    };
                    await this.$store.dispatch('setting/setNewValue', payload);
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('settings.type') }));
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('saving-setting');
                });
            },

            /**
             * Toggle reset collapse value
             */
            toggleCollapse() {
                this.resetCollapseOpen = !this.resetCollapseOpen;
            },

            /**
             * Reset settings
             */
            resetSettings() {
                this.$wait.start('resetting-setting');
                const params = {
                    slug: this.slug,
                };
                this.$services.Setting.reset(params)
                    .then((response) => {
                        this.model = response.data.results;
                        this.resetCollapseOpen = false;
                        this.$toast.success(this.$t('settings.messages.reset_success'));
                    })
                    .catch((err) => {
                        this.handleError(err);
                    })
                    .finally(() => {
                        this.$wait.end('resetting-setting');
                    });
            },

        },

    };

</script>
