<template>
    <div>
        <y-loading
            :active="$wait.is('pagination-loading')"
            height="50vh"
        >
            <div
                v-if="form && form.length"
                class="row"
            >
                <div class="col-12 col-lg-8 col-center">
                    <y-form
                        v-model="model"
                        :params="form"
                    />
                </div>
            </div>
        </y-loading>
    </div>
</template>

<script>
    import { YForm } from '@deps';
    import ProfileTabs from '@/modules/persons/mixins/ProfileTabs';

    export default {
        name: 'ProfileBasicInfo',

        components: {
            YForm,
        },

        mixins: [ProfileTabs],

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: null,
                form : null,
            };
        },

        watch: {
            content: {
                /**
                 * Adopt data
                 */
                handler() {
                    this.$set(this, 'model', this.content.results);
                    this.$set(this, 'form', this.content.metadata.form);
                },
                deep: true,
            },
        },
    };
</script>
