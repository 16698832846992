<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-user-info"
    >
        <template slot="functions">
            <y-button
                size="xs"
                class="blue"
                tag="router-link"
                :to="{ name: 'persons-profile-edit' }"
            >
                {{ $t('profile.edit.title') }}
            </y-button>
            <y-button
                size="xs"
                @click="logout"
            >
                {{ $t('auth.logout') }}
            </y-button>
        </template>

        <div class="container">
            <div class="user-info">
                <span class="avatar">
                    <img
                        v-if="profile && profile.avatar"
                        :src="profile.avatar[0].link"
                        :alt="profile.name + ' Avatar Thumb'"
                    >
                    <i
                        v-else
                        class="icon md-account-circle"
                    />
                </span>

                <div>
                    <span
                        v-if="profile"
                        class="username"
                    >{{ profile.name }}</span>
                    <span
                        v-else
                        class="username"
                    >{{ $t('fields.username') }}</span>
                </div>
            </div>
            <span class="time">{{ timestamp | digits }}</span>
        </div>
    </y-panel>
</template>

<script>
    import moment from 'moment-timezone';
    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'UserInfoWidget',

        mixins: [WidgetMixin],

        props: {

            /**
             * Widget element
             */
            element: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                profile  : this.$store.getters['layout/profile'],
                timestamp: null,
            };
        },

        watch: {
            /**
             * Watch if profile layout is loaded
             */
            '$store.state.layout.layout.profile': function () { // eslint-disable-line func-names
                this.profile = this.$store.getters['layout/profile'];
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            setInterval(() => {
                this.changeTimezone(this.$store.getters['auth/timezone']);
            }, 1000);
        },

        methods: {
            /**
             * Change Timezone
             *
             * @param tz
             */
            changeTimezone(tz) { // eslint-disable-line no-unused-vars
                const time = moment(new Date()).tz(tz);
                this.timestamp = time.format('HH:mm:ss');
            },

            /**
             * Logout from VueX and redirect to login page
             */
            logout() {
                this.$store.dispatch('auth/logout').then(() => {
                    this.$router.push({ name: 'auth-login' });
                });
            },

        },

    };
</script>
