<template>
    <div>
        <y-form-uploader
            :key="keyHelper"
            ref="uploader"
            :max-file-size="element.max_volume_files"
            :accepted-files="element.allowed_extensions"
            :max-files="element.max_number_files"
            :payload="element.payload"
            :files="avatarImg"
            no-label
            simple
            small
            @success="$emit('success', $event)"
            @remove="$emit('remove')"
        />
        <slot />
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import YFormUploader from '@deps/form/elements/Uploader';

    export default {
        name: 'ProfileAvatar',

        components: {
            YFormUploader,
        },

        props: {
            element: [Object, Array],

            avatarImg: Array,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                keyHelper: generateId(),
            };
        },
    };
</script>
