<template>
    <div>
        <y-page-head
            :title="$t('person.user.edit_user')"
            icon="md-account-edit"
        >
            <template slot="actions">
                <!-- Save -->
                <y-button
                    color="blue"
                    loading-on="submitting-form"
                    @click.prevent.native="save"
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <y-profile-editor
                v-model="model"
                :settings="settings"
                :has-locations="hasLocations"
                :has-avatar="hasAvatar"
                :has-avatar-resource="hasAvatarResource"
            />
        </y-loading>
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import GlobalMixin from '@/mixins/Global';

    // Components
    import YProfileEditor from '@/modules/persons/components/profile/Editor';

    export default {

        name: 'UserEdit',

        components: {
            YProfileEditor,
        },

        mixins: [PageMixin, FormMixin, GlobalMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.persons.users.list')),
                this.$bc(this.$t('breadcrumbs.persons.users.edit')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('breadcrumbs.persons.users.edit'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model   : null,
                settings: {},

                hasLocations     : false,
                hasAvatar        : false,
                hasAvatarResource: false,
            };
        },

        computed: {
            /**
             * Return user id from router params
             */
            id() {
                return this.$route.params.id;
            },
        },

        methods: {
            /**
             * Fetch grid
             *
             * @returns {*}
             */
            fetch() {
                const params = {
                    id: this.id,
                };

                return this.$services.Person.userEditForm(params).then((response) => {
                    this.fetchAdapter(response);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save profile changes
             *
             * @returns {*}
             */
            save() {
                this.$wait.start('submitting-form');
                return this.$services.Person.usersPanelSave(this.model).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('profile.edit.title') }));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },

            /**
             * Fetch response adapter
             *
             * @param {object} response
             */
            fetchAdapter(response) {
                const { results, metadata } = response.data;

                const hasAvatar = !!(Array.isArray(results.avatar));
                const hasLocations = Array.isArray(metadata.locations);

                this.hasLocations = hasLocations;
                this.hasAvatar = hasAvatar;
                this.hasAvatarResource = !!(Array.isArray(results.avatar) && results.avatar.length);

                const model = {
                    ...results,
                };

                const settings = {
                    form: metadata.form,
                };

                settings.avatarImg = hasAvatar ? results.avatar : [];
                if (hasAvatar && results.avatar.length) {
                    model.avatar = [results.avatar[0]];
                    settings.avatarOptions = metadata.avatar ? metadata.avatar : null;
                }
                if (hasLocations) {
                    settings.locations = metadata.locations;
                }

                this.model = model;
                this.settings = settings;

                this.registerGlobalModals(metadata.modals);
            },
        },

    };

</script>
