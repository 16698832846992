<template>
    <y-modal
        ref="Modal"
        :title="$t('profile.google.backup_codes')"
        size="xs"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('loading-codes')"
                height="200px"
            >
                <p
                    v-if="!codes"
                    v-html="$t('profile.google.backup_codes_desc')"
                />
                <p
                    v-else
                    v-html="$t('profile.google.new_backup_codes_desc')"
                />

                <div class="alert ta-c green pt10 pb10">
                    <strong>
                        {{ $t('profile.google.used_codes', { count: used }) | digits }}
                    </strong>
                </div>

                <y-button
                    v-if="!codes"
                    block
                    color="blue"
                    loading-on="generating-codes"
                    @click.native="generate"
                >
                    {{ $t('fields.generate_backup_codes') }}
                </y-button>

                <y-row
                    v-if="codes"
                    class="mt30"
                >
                    <y-col
                        v-for="code in codes"
                        :key="code"
                        xs="6"
                    >
                        <div class="code-item">
                            {{ code | digits }}
                        </div>
                    </y-col>
                </y-row>
            </y-loading>
        </div>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {
        name: 'BackupCodesModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                codes: null,
                used : 0,
            };
        },

        methods: {

            /**
             * Save form
             */
            generate() {
                this.$wait.start('generating-codes');

                this.$services.Person.fetchBackupCodes().then((response) => {
                    this.codes = response.data.results.map((el) => el.code);
                    this.used = 0;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('generating-codes'));
            },

            /**
             * Save form
             */
            fetch() {
                this.$wait.start('loading-codes');

                this.$services.Person.usedBackupCodes().then((response) => {
                    this.used = response.data.results.used;
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => this.$wait.end('loading-codes'));
            },

            /**
             * Open modal
             */
            open() {
                this.$refs.Modal.open();
                this.fetch();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.codes = null;
                this.used = 0;
            },

        },
    };
</script>
